import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { postLogin } from '@apis/auth';
import { COOKIE_DOMAIN } from '@helpers/utils';

import Link from '@components/Link';
import Hero from '@components/PageComponents/Hero/Hero';
import BaseLayout from '@components/Layout/BaseLayout';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import Password from '@hiredigital/ui/Input/Password/Password';
import Loader from '@hiredigital/ui/Loader';
import Logo from '@hiredigital/ui/Logo';

import Styles from './Login.module.scss';
const LogoSection = dynamic(() => import('@components/PageComponents/LogoSection/LogoSection'));
const cookies = new Cookies();

const LoginPage = ({ initialUsername }) => {
  const router = useRouter();
  const { query } = router;
  const [tokenExpired, setTokenExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState({ value: initialUsername, valid: true });
  const [password, setPassword] = useState({ value: '', valid: true, error: null });

  useEffect(() => {
    const token = cookies.get('token');
    if (query?.clear) {
      cookies.remove('token');
    }
    if (query?.expired) {
      setTokenExpired(true);
    }
    if (token && typeof window !== 'undefined') {
      if (query?.next) {
        window.location.href = decodeURIComponent(query?.next);
      } else {
        window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/dashboard`;
      }
    }
  }, [query]);

  const goToNext = () => {
    if (query?.next) {
      window.location.href = decodeURIComponent(query.next);
    } else {
      window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/dashboard`;
    }
  };

  const handlePasswordKeyPress = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    setLoading(true);
    const user = {
      username: username.value,
      password: password.value,
    };

    try {
      // Identify User Block
    } catch (err) {
      console.error(err);
    }

    postLogin(user)
      .then(({ data: { key } }) => {
        cookies.set('token', key, COOKIE_DOMAIN);
        goToNext();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        try {
          setPassword({
            value: password.value,
            valid: false,
            error: error.response.data.nonFieldErrors[0],
          });
        } catch (e) {}

        try {
          setPassword({
            value: password.value,
            valid: false,
            error: error.response.data.password[0],
          });
        } catch (e) {}
      });
  };

  return (
    <BaseLayout
      pageHead={{
        title: `Login | Hire Digital`,
      }}>
      <div className={Styles.container}>
        <Hero parent={Hero.Page.LOGIN} square={true}>
          <div className={Styles.contentWrapper}>
            <div className={Styles.formContainer} data-test-id='login-form'>
              <Link href='/' className={Styles.logoContainer}>
                <Logo className={Styles.logo} type={Logo.Type.WHITE} site={'3'} />
              </Link>
              <div className={Styles.card}>
                <div className={Styles.header}>
                  <div className={Styles.headline}>{`Login`}</div>

                  <p className={Styles.description}>{`Welcome back to Hire Digital`}</p>

                  {tokenExpired && (
                    <p className={Styles.errorMessage}>
                      {`Your login details have expired. Please login again.`}
                    </p>
                  )}
                </div>
                <div className={Styles.inputContainer}>
                  <TextInput
                    name='username'
                    label='Email Address'
                    error={!username.valid && `Enter your email address.`}
                    value={username.value}
                    onChange={(e) =>
                      setUsername({ value: e.target.value, valid: e.target.value.length >= 1 })
                    }
                  />
                </div>
                <div className={Styles.inputContainer}>
                  <Password
                    name='password'
                    value={password.value}
                    error={!password.valid && (password.error || `Enter your password.`)}
                    placeholder={`Password`}
                    onKeyPress={handlePasswordKeyPress}
                    onChange={(e) =>
                      setPassword({ value: e.target.value, valid: e.target.value.length >= 1 })
                    }
                  />
                  <Link href='/reset' className={Styles.forgotPwd}>
                    {`Forgot Password`}
                  </Link>
                </div>

                <div className={Styles.loginActionsContainer}>
                  <Button
                    className={Styles.loginButton}
                    name='submit'
                    type={Button.Type.BLUE}
                    size={Button.Size.FULLWIDTH}
                    isLoading={loading}
                    onClick={handleSubmit}>
                    {`Log In`}
                  </Button>
                </div>
              </div>
              <div className={Styles.signup}>
                <span>
                  {`No account yet? Sign up as a `}
                  <Link href='/talent/register' className={Styles.signupLink}>
                    {`Talent`}
                  </Link>
                  {` or `}
                  <Link href='/schedule-call' className={Styles.signupLink}>
                    {`Company`}
                  </Link>
                  {`.`}
                </span>
              </div>
            </div>
          </div>
          <LogoSection removeBackground />
        </Hero>
      </div>
    </BaseLayout>
  );
};

export default LoginPage;
