import format from 'date-fns/format';
export const getName = (url) => {
  if (url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }
  return;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const acceptedFiles = [
  'image/*',
  'application/zip',
  'application/pdf',
  'video/*',
  'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/docx',
  'application/doc',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const COOKIE_DOMAIN = {
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ? process.env.NEXT_PUBLIC_COOKIE_DOMAIN : '',
  httpOnly: false,
  sameSite: false,
  path: '/',
};

export const parseCookie = (str) => {
  if (!str) {
    return {};
  }
  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
};

// export const isOfTypeUuId = (value) => {
//   const accessKeyRegex =
//     /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
//   return accessKeyRegex.test(value);
// };

// export const toReadableDate = (date, fmt) => {
//   return format(new Date(date), fmt || 'dd MMM yyyy');
// };

// export const dateSort = (a, b) => {
//   const bEnd = b.endYear ? new Date(b.endYear, b.endMonth).valueOf() : new Date().valueOf();
//   const aEnd = a.endYear ? new Date(a.endYear, a.endMonth).valueOf() : new Date().valueOf();
//   return bEnd < aEnd ? -1 : 1;
// };

// export const setValue = (entry, value) => {
//   if (entry === undefined) {
//     // eslint-disable-next-line
//     console.warn({ err: new Error('entry is undefined on setValue') });
//     return '';
//   }
//   // eslint-disable-next-line
//   entry = entry.replace(/(\{(\w+)\})/g, (match, grp, key) => {
//     const ret = value[key];
//     if (ret !== null && ret !== undefined) {
//       return ret;
//     }
//     return match;
//   });

//   return entry;
// };

//
// testing a replacement for date-fns format. To check if we can remove it from every page load
// Will replace with verbose but plain JS
// format(new Date(), 'MMM yyyy'),
//

const getRandomLetter = (length) => {
  var randomChars = 'abcdefghijklmnopqrstuvwxyz';
  return randomChars.charAt(Math.floor(Math.random() * randomChars.length));
};

export const censorString = (input) => {
  const randomized = input
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');

  return randomized.charAt(0).toUpperCase() + randomized.slice(1);
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const replaceJson = (jsonFile, count) => {
  let entry = JSON.stringify(jsonFile);
  const today = new Date();
  const replacement = {
    siteName: `Hire Digital`,
    contactEmail: `${process.env.NEXT_PUBLIC_CONTACT_EMAIL}`,
    month: `${months[today.getUTCMonth()]} ${today.getFullYear()}`,
    count: `${count}`,
  };

  // const replacementKeys = Object.keys(replacement);
  entry = entry.replace(/(\{(\w+)\})/g, (match, grp, key) => {
    const ret = replacement[key];

    if (ret !== null && ret !== undefined) {
      return ret;
    }
    return match;
  });
  return JSON.parse(entry);
};

export const isValidImage = (imageUrl) => {
  return imageUrl.match(/\.(jpeg|JPEG|JPG|GIF|PNG|svg|SVG|WEBP|webp|jpg|gif|png)$/) != null;
};

export const removeNullProps = (obj) => {
  const returnObj = {};
  Object.entries(obj).forEach(([idx, v]) => {
    if (Array.isArray(v)) {
      if (v.length) returnObj[idx] = v;
    } else {
      if (!!v) returnObj[idx] = v;
    }
  });
  return returnObj;
};
